import React, { useEffect } from "react";
import styles from "../styles/Projects.module.css";
import "../../globals.css";
import { Helmet } from "react-helmet";
import Hero from "../../components/Hero";
import ProjectContent from "../../components/projects/ProjectContent";
import ProjectOutboundButton from "../../components/projects/ProjectOutboundButton";
import ProjectVideo from "../../components/projects/ProjectVideo";

const Plentyofolios = ({ nextProjectName }) => {
  const cta = "#7662d7";
  const nextcta = "#DE64F2";
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    document.documentElement.style.setProperty("--cta", cta);
    document.documentElement.style.setProperty("--next-cta", nextcta);
  }, []);
  const nextUrl = "/" + nextProjectName.toLowerCase();

  const thumbnail = "/assets/PlentyofoliosThumbnail.png";

  const isMobile = window.innerWidth < 768;

  return (
    <>
      <Helmet>
        <title>Maguire McMahon · Plentyofolios</title>
        <meta name="theme-color" content={cta}></meta>
        <meta name="apple-mobile-web-app-status-bar-style" content={cta}></meta>
        <meta
          name="description"
          content="Plentyofolios is a collection of inspiring portfolio websites that I have discovered in my experience searching for inspiration. I created it to honestly free up my bookmarks bar as it's been cluttered with amazing resources I have found. It was built additionally to learn about NextJS 14 and the new app router."
        />
      </Helmet>
      <div className={`${styles.projectLayout} page-layout`}>
        <Hero h1={"plentyofolios"} h2={"a collection of inpsiring portfolios"} cta={cta}>
          <div className={styles.projectHeroInfo}>
            <div className={styles.verticalFlex}>
              <h3>Role</h3>
              <p>Full-Stack Developer</p>
            </div>
            <div className={styles.verticalFlex}>
              <h3>Tech Stack</h3>
              <p>Frontend: NextJS, Tailwind</p>
              <p>Backend: NextJS, Vercel</p>
              <p>Database & Hosting: Vercel</p>
            </div>
            <div className={styles.verticalFlex}>
              <h3>Timeline</h3>
              <p>Summer '24</p>
            </div>
          </div>
        </Hero>

        {/* MAIN CONTENT */}
        <ProjectContent>
          <div className={styles.projectContent}>
            <div className={styles.projectContentBackground}></div>
            { isMobile ? <ProjectVideo videoSource="/assets/PlentyofoliosDesktopVid.mp4" posterImage={thumbnail} type="mobile"/> : <ProjectVideo videoSource="/assets/PlentyofoliosDesktopVid.mp4" posterImage={thumbnail} type="desktop"/>}
            <div className={styles.projectDescription}>
              <h3>Overview</h3>
              <p>
                Plentyofolios is a collection of inspiring portfolio websites
                that I have discovered in my experience searching for
                inspiration. I created it to honestly free up my bookmarks bar
                as it's been cluttered with amazing resources I have found. It
                was built additionally to learn about NextJS 14 and the new app
                router.
              </p>
              <br />
              <h3>Features</h3>
              <p>
                This project was built using NextJS 14, Tailwind CSS, and
                Vercel. The frontend was first built with mock data. I initially
                only showed one photo of the portfolio's homepage, along with
                the author's name. After iteration, I added two additional,
                smaller photos to give a better idea of the portfolio's style,
                design choices, and overall aesthetic. Finally, I moved the
                author's name directly into the images, and added hover effects
                to direct the users directly to the portfolio.
              </p>
              <br />
              <h3>Future plans</h3>
              <p>
                I have allowed users to submit their portfolios for review and
                to be added. I hope to continue to add incredible portfolios as
                I continue to explore the space. I also plan to add a search
                feature to allow users to search for portfolios by name, author,
                or other keywords and possibly use ChatGPT's API to generate a
                description of the portfolio and technologies used.
              </p>
            </div>
          </div>

          {/* BUTTONS ON SIDE DESKTOP, BOTTOM MOBILE */}
          <div className={styles.buttonContainer}>
            <ProjectOutboundButton
              url="https://plentyofolios.vercel.app/"
              text={"Source Code"}
              buttonType="source"
            />
            <ProjectOutboundButton
              url={nextUrl}
              text={nextProjectName}
              buttonType="outbound"
            />
          </div>
        </ProjectContent>
      </div>
    </>
  );
};

export default Plentyofolios;
