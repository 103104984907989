import React, { useEffect } from "react";
import styles from "../styles/Projects.module.css";
import "../../globals.css";
import { Helmet } from "react-helmet";
import Hero from "../../components/Hero";
import ProjectContent from "../../components/projects/ProjectContent";
import ProjectOutboundButton from "../../components/projects/ProjectOutboundButton";
import ProjectVideo from "../../components/projects/ProjectVideo";

const Jarvis = ({ nextProjectName }) => {
  const cta = "#DE64F2";
  const nextcta = "#ff453a";
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    document.documentElement.style.setProperty("--cta", cta);
    document.documentElement.style.setProperty("--next-cta", nextcta);
  }, []);
  const nextUrl = "/" + nextProjectName.toLowerCase();

  const thumbnail = "/assets/JarvisThumbnail.png";

  const isMobile = window.innerWidth < 768;

  return (
    <>
      <Helmet>
        <title>Maguire McMahon · Jarvis</title>
        <meta name="theme-color" content={cta}></meta>
        <meta name="apple-mobile-web-app-status-bar-style" content={cta}></meta>
        <meta
          name="description"
          content="Jarvis is a voice assistant that I created to help me automate common tasks that I have when using my computer. I initially created it as a proof-of-concept, but I have since expanded it to be a fully functional voice assistant that I use daily."
        />
      </Helmet>

      <div className={`${styles.projectLayout} page-layout`}>
        <Hero
          h1={"jarvis"}
          h2={"an automated voice-activated assistant"}
          cta={cta}
        >
          <div className={styles.projectHeroInfo}>
            <div className={styles.verticalFlex}>
              <h3>Role</h3>
              <p>Software Developer</p>
            </div>
            <div className={styles.verticalFlex}>
              <h3>Tech Stack</h3>
              <p>Python</p>
              <p>libraries: OpenAI, porcupine</p>
            </div>
            <div className={styles.verticalFlex}>
              <h3>Timeline</h3>
              <p>Winter '23</p>
            </div>
          </div>
        </Hero>

        {/* MAIN CONTENT */}
        <ProjectContent>
          <div className={styles.projectContent}>
            <div className={styles.projectContentBackground}></div>
            {isMobile ? <ProjectVideo videoSource="/assets/JarvisDesktopVid.mp4" posterImage={thumbnail} type="mobile"/> : <ProjectVideo videoSource="/assets/JarvisDesktopVid.mp4" posterImage={thumbnail} type="desktop"/>}
            <div className={styles.projectDescription}>
              <h3>Overview</h3>
              <p>
                Jarvis is a voice assistant that I created to help me automate
                common tasks that I have when using my computer. I initially
                created it as a proof-of-concept, but I have since expanded it
                to be a fully functional voice assistant that I use daily. It is
                built using Python and the OpenAI API, and it is able to perform
                a number of tasks like opening applications, answering
                questions, searching my calendar, and more.
              </p>
              <br />
              <h3>Features</h3>
              <ul>
                <li>Open application</li>
                <li>
                  Spotify commands{" "}
                  <ul>
                    <li>Play song by name</li>
                    <li>Play artist by name</li>
                    <li>Play playlist by name</li>
                    <li>Play album by name</li>
                  </ul>
                </li>
                <li>
                  Calendar Commands{" "}
                  <ul>
                    <li>Find event</li>
                    <li>Create event</li>
                    <li>Summary of weekly events</li>
                  </ul>
                </li>
                <li>
                  Gmail Commands{" "}
                  <ul>
                    <li>Find email</li>
                    <li>Summary of recent emails</li>
                  </ul>
                </li>
              </ul>
              <p>
                The main loop of Jarvis should make sense to anyone, regardless
                of your coding or programming language. It goes as follows:
              </p>
              <ol>
                <li>Listen for wake word (Jarvis)</li>
                <li>When heard, beep and listen for command</li>
                <li>
                  If command exactly matches a known command, process it &
                  return to 1
                </li>
                <li>
                  If not, pass the command to OpenAI API with custom
                  instructions
                  <ul>
                    <li>
                      If command intent matches known command, process it &
                      return to 1
                    </li>
                    <li>Else, speak response & return to 1</li>
                  </ul>
                </li>
              </ol>
              <br />
              <p>
                After implementing the main loop, the next step was implementing
                custom commands that OpenAI cannot process on its own i.e.
                opening applications or playing a song from spotify. I did this
                by creating a dictionary of commands and their intents, and then
                processing the command based on the intent. For example, if the
                command is "open spotify", the intent is "open", and the
                argument is "spotify". I then use the subprocess library to open
                the application.
              </p>
              <br />
              <h3>Future plans</h3>
              <p>
                As I continue to use Jarvis, I am currently collecting all data
                that is sent to and recieved from OpenAI. This allows me to
                fine-tune models and improve the accuracy of Jarvis. I am also
                working on adding more custom commands such as support for
                reading and writing to files, and I am working on adding support
                for more applications. If you have any suggestions or wish to
                try Jarvis out for yourself, feel free to reach out to me!
              </p>
            </div>
          </div>

          {/* BUTTONS ON SIDE DESKTOP, BOTTOM MOBILE */}
          <div className={styles.buttonContainer}>
            <ProjectOutboundButton
              url="https://github.com/mimcmahon20/Jarvis"
              text={"Source Code"}
              buttonType="source"
            />
            <ProjectOutboundButton
              url={nextUrl}
              text={nextProjectName}
              buttonType="outbound"
            />
          </div>
        </ProjectContent>
      </div>
    </>
  );
};

export default Jarvis;
