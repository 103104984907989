import React, { useEffect } from "react";
import styles from "../styles/Projects.module.css";
import "../../globals.css";
import { Helmet } from "react-helmet";
import Hero from "../../components/Hero";
import ProjectContent from "../../components/projects/ProjectContent";
import ProjectOutboundButton from "../../components/projects/ProjectOutboundButton";
import ProjectVideo from "../../components/projects/ProjectVideo";

const MaguireCodes = ({ nextProjectName }) => {
  const cta = "#46B45F";
  const nextcta = "#89A8E4";
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    document.documentElement.style.setProperty("--cta", cta);
    document.documentElement.style.setProperty("--next-cta", nextcta);
  }, []);
  const nextUrl = "/" + nextProjectName.toLowerCase();

  const thumbnail = "/assets/MaguireCodesThumbnail.png";

  const isMobile = window.innerWidth < 768;

  return (
    <>
      <Helmet>
        <title>Maguire McMahon · MaguireCodes</title>
        <meta name="theme-color" content={cta}></meta>
        <meta name="apple-mobile-web-app-status-bar-style" content={cta}></meta>
        <meta
          name="description"
          content="MaguireCodes is a full-stack tech blog written by Maguire McMahon. It is built using React, Node.js, Express, and Firebase."
        />
      </Helmet>
      <div className={`${styles.projectLayout} page-layout`}>
        <Hero h1={"maguire codes"} h2={"a full-stack tech blog"} cta={cta}>
          <div className={styles.projectHeroInfo}>
            <div className={styles.verticalFlex}>
              <h3>Role</h3>
              <p>Full-Stack Developer</p>
            </div>
            <div className={styles.verticalFlex}>
              <h3>Tech Stack</h3>
              <p>Frontend: React, CSS Modules</p>
              <p>Backend: Node + Express</p>
              <p>Database: Firebase</p>
            </div>
            <div className={styles.verticalFlex}>
              <h3>Timeline</h3>
              <p>Fall '23</p>
            </div>
          </div>
        </Hero>

        {/* MAIN CONTENT */}
        <ProjectContent>
          <div className={styles.projectContent}>
            <div className={styles.projectContentBackground}></div>
            { isMobile ? <ProjectVideo videoSource="/assets/MaguireCodesDesktopVid.mp4" posterImage={thumbnail} type="mobile"/> : <ProjectVideo videoSource="/assets/MaguireCodesDesktopVid.mp4" posterImage={thumbnail} type="desktop"/>}
            <div className={styles.projectDescription}>
              <h3>Overview</h3>
              <p>
                MaguireCodes is the first fullstack application that I have
                developed individually. It is a dark-mode, tech blog where I
                write about my projects, interesting articles & videos, and a
                Wednesday Website of the Week series. I originally created the
                blog as an excuse to write about my struggles and growth in
                2023.
              </p>
              <br />
              <h3>Features</h3>
              <p>
                This blog highlights a number of features that I am proud of. As
                my first full-stack application, I wanted to learn as much about
                backend technologies, APIs, and deployment as possible. I went
                into the project with the goal of not having to directly edit
                HTMl/React components in order to post more blogs. I wanted to
                simply be able to type out a blog and post it.
              </p>
              <p>
                The feature I am most proud of is my admin panel. Using Firebase
                as my database allowed me to easily implement user
                authentication for accessing the panel, and using Router I
                easily could redirect to the login page if not authenticated.
                From within this panel I have the ability to create, edit,
                update, and delete <i>(CRUD)</i> all posts. The hardest part of
                this process was creating my own decoder for the content
                textfield.
              </p>
              <br />
              <h3>Future plans</h3>
              <p>
                I hope to continue to improve the UI and usability of my blog. I
                originally implemented it with a light and dark mode, but
                decided to go for a pure dark version on launch. Additionally, I
                am hoping to explore a website for WWW every week of 2024.
                Finally, I hope to continue posting about my learnings in and
                outside of the classroom, and all projects of note.
              </p>
            </div>
          </div>

          {/* BUTTONS ON SIDE DESKTOP, BOTTOM MOBILE */}
          <div className={styles.buttonContainer}>
            <ProjectOutboundButton
              url="https://github.com/mimcmahon20/MaguireCodes"
              text={"Source Code"}
              buttonType="source"
            />
            <ProjectOutboundButton
              url={nextUrl}
              text={nextProjectName}
              buttonType="outbound"
            />
          </div>
        </ProjectContent>
      </div>
    </>
  );
};

export default MaguireCodes;
