import React, { useEffect } from "react";
import styles from "../styles/Projects.module.css";
import "../../globals.css";
import { Helmet } from "react-helmet";
import Hero from "../../components/Hero";
import ProjectContent from "../../components/projects/ProjectContent";
import ProjectOutboundButton from "../../components/projects/ProjectOutboundButton";
import ProjectVideo from "../../components/projects/ProjectVideo";

const MagOS = ({ nextProjectName }) => {
  const cta = "#ff453a";
  const nextcta = "#F1A638";
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    document.documentElement.style.setProperty("--cta", cta);
    document.documentElement.style.setProperty("--next-cta", nextcta);
  }, []);
  const nextUrl = "/" + nextProjectName.toLowerCase();

  const thumbnail = "/assets/MagOSThumbnail.png";

  const isMobile = window.innerWidth < 768;

  return (
    <>
      <Helmet>
        <title>Maguire McMahon · MagOS</title>
        <meta name="theme-color" content={cta}></meta>
        <meta name="apple-mobile-web-app-status-bar-style" content={cta}></meta>
        <meta
          name="description"
          content="MagOS is a frontend clone of MacOS Sonoma. It is built using React, and styled using CSS Modules."
        />
      </Helmet>
      <div className={`${styles.projectLayout} page-layout`}>
        <Hero h1={"mag os"} h2={"a frontend clone of MacOS Sonoma"} cta={cta}>
          <div className={styles.projectHeroInfo}>
            <div className={styles.verticalFlex}>
              <h3>Role</h3>
              <p>Frontend Developer</p>
            </div>
            <div className={styles.verticalFlex}>
              <h3>Tech Stack</h3>
              <p>Frontend: React, CSS Modules</p>
              <p>Deployment: Netlify</p>
            </div>
            <div className={styles.verticalFlex}>
              <h3>Timeline</h3>
              <p>Fall '23</p>
            </div>
          </div>
        </Hero>

        {/* MAIN CONTENT */}
        <ProjectContent>
          <div className={styles.projectContent}>
            <div className={styles.projectContentBackground}></div>
            { isMobile ? <ProjectVideo videoSource="/assets/MagOSDesktopVid.mp4" posterImage={thumbnail} type="mobile"/> : <ProjectVideo videoSource="/assets/MagOSDesktopVid.mp4" posterImage={thumbnail} type="desktop"/>}
            <div className={styles.projectDescription}>
              <h3>Overview</h3>
              <p>
                MagOS is a frontend clone of MacOS Sonoma. It is built using
                React, and styled using CSS Modules. I had the idea for this
                project when I was customizing my Desktop in the new OS Sonoma.
                The widgets were inspiring, and I wondered what the process of
                recreating a desktop feeling would be like. I decided to take on
                the challenge, and I am very happy with the results.
              </p>
              <br />
              <h3>Features</h3>
              <ul>
                <li>
                  Application windows with minimize, close, and maximize buttons
                </li>
                <li>Resizable windows</li>
                <li>
                  Messages app featuring AI responses and custom characters
                </li>
                <li>
                  Notes app with ability to create, edit, update, and delete
                  notes
                </li>
                <li>Mock Terminal</li>
                <li>Settings app with ability to change wallpaper</li>
                <li>Safari application defaulting to my portfolio website</li>
              </ul>
              <p>
                This webapp features a functional desktop, with custom
                applications meant to mimic those found on a Mac computer. The
                desktop is fully responsive, and application windows are
                resizable and movable. The application also includes the login
                screen, something that I was really proud of creating.
              </p>
              <br />
              <h3>Future plans</h3>
              <p>
                I have a long list of possible features to add, from a voice
                assistant (siri) similar to my Jarvis project, to a music player
                utilizing Spotify API, to custom desktop app icons and widgets.
                I am excited to continue working on this project, and I hope to
                have a fully functional desktop experience in the future.
              </p>
            </div>
          </div>

          {/* BUTTONS ON SIDE DESKTOP, BOTTOM MOBILE */}
          <div className={styles.buttonContainer}>
            <ProjectOutboundButton
              url="https://github.com/mimcmahon20/MacOsSonoma"
              text={"Source Code"}
              buttonType="source"
            />
            <ProjectOutboundButton
              url={nextUrl}
              text={nextProjectName}
              buttonType="outbound"
            />
          </div>
        </ProjectContent>
      </div>
    </>
  );
};

export default MagOS;
