import React, { useEffect } from "react";
import styles from "../styles/Projects.module.css";
import "../../globals.css";
import { Helmet } from "react-helmet";
import Hero from "../../components/Hero";
import ProjectContent from "../../components/projects/ProjectContent";
import ProjectOutboundButton from "../../components/projects/ProjectOutboundButton";
import ProjectVideo from "../../components/projects/ProjectVideo";

const WeightClub = ({ nextProjectName }) => {
  const cta = "#F1A638";
  const nextcta = "#46B45F";
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    document.documentElement.style.setProperty("--cta", cta);
    document.documentElement.style.setProperty("--next-cta", nextcta);
  }, []);
  const nextUrl = "/" + nextProjectName.toLowerCase();

  const thumbnail = "/assets/WeightClubThumbnail.png";

  const isMobile = window.innerWidth < 768;

  return (
    <>
      <Helmet>
        <title>Maguire McMahon · The Weight Club Redesign</title>
        <meta name="theme-color" content={cta}></meta>
        <meta name="apple-mobile-web-app-status-bar-style" content={cta}></meta>
        <meta
          name="description"
          content="The Weight Club is a local gym in my college town Blacksburg, VA. I have been a member since 2020, and have always been a fan of their gym. However, their website is outdated for a plethora of reasons. I decided to take it upon myself to redesign their website and submit it to them as a proposal."
        />
      </Helmet>
      <div className={`${styles.projectLayout} page-layout`}>
        <Hero
          h1={"the weight club"}
          h2={"a proposed website redesign"}
          cta={cta}
        >
          <div className={styles.projectHeroInfo}>
            <div className={styles.verticalFlex}>
              <h3>Role</h3>
              <p>Designer Developer</p>
            </div>
            <div className={styles.verticalFlex}>
              <h3>Tech Stack</h3>
              <p>Webflow and Figma</p>
            </div>
            <div className={styles.verticalFlex}>
              <h3>Timeline</h3>
              <p>Summer '24</p>
            </div>
          </div>
        </Hero>

        {/* MAIN CONTENT */}
        <ProjectContent>
          <div className={styles.projectContent}>
            <div className={styles.projectContentBackground}></div>
            { isMobile ? <ProjectVideo videoSource="/assets/WeightClubDesktopVid.mp4" posterImage={thumbnail} type="mobile"/> : <ProjectVideo videoSource="/assets/WeightClubDesktopVid.mp4" posterImage={thumbnail} type="desktop"/>}
            <div className={styles.projectDescription}>
              <h3>Overview</h3>
              <p>
                The Weight Club is a local gym in my college town Blacksburg,
                VA. I have been a member since 2020, and have always been a fan
                of their gym. However, their website is outdated for a plethora
                of reasons. I decided to take it upon myself to redesign their
                website and submit it to them as a proposal.
              </p>
              <br />
              <h3>Problems With Existing Site</h3>
              <ul>
                <li>Header covering over 30% of the screen</li>
                <li>Buttons without clear ARIA labels</li>
                <li>Text not accessible to screen readers</li>
                <li>Images not optimized for proper loading</li>
                <li>Does not funnel users properly towards call to action</li>
                <li>Improper and inconsistent typefaces</li>
                <li>Unable to join/check on membership online</li>
              </ul>
              <br />
              <h3>Updated Design System</h3>
              <p>
                For the redesign, I decided to go with the dark theme default
                they already use. Additionally, I tried to use their typefaces
                of choice: Oswald and Poppins. For their buttons, they had no
                distinction between a primary and secondary button. I tried to
                keep their color scheme, but added a few more colors to the
                palette. I went for a more rounded, modern approach, to help
                match current design trends.
              </p>
              <p>
                I also added a few more sections to the homepage, including a
                section for testimonials that were acquired via google reviews.
                I also improved their class list section on desktop and mobile,
                improving readability by adding a semi-opaque background
                gradient to the image. Additionally, I included a final
                'hero-section' before the footer of the homepage, once again
                pushing users to sign-up for membership. I also adjusted the
                footer to continue to funnel users to their call to action.
              </p>
              <br />
              <h3>Future plans</h3>
              <p>
                I have not heard back from The Weight Club yet, but I am hopeful
                that they will consider my proposal. I would love to work with
                them to implement this design, and potentially even help them
                with their online presence in the future. Additionally, I would
                like to add a blog section to their website, where they can post
                workout tips, recipes, and other fitness-related content. This
                would further improve their already decent SEO, and with a small
                market of Blacksburg it would be easy to rank high on google and
                get the most new signups possible.
              </p>
            </div>
          </div>

          {/* BUTTONS ON SIDE DESKTOP, BOTTOM MOBILE */}
          <div className={styles.buttonContainer}>
            <ProjectOutboundButton
              url="https://theweightclubmockup.webflow.io/"
              text={"See Mockup"}
              buttonType="source"
            />
            <ProjectOutboundButton
              url={nextUrl}
              text={nextProjectName}
              buttonType="outbound"
            />
          </div>
        </ProjectContent>
      </div>
    </>
  );
};

export default WeightClub;
